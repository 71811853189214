import * as React from "react";

type Props = {
  title?: string;
  image?: string;
  children?: React.ReactNode;
};

const PageHeader = ({ title, image, children }: Props): JSX.Element => {
  if (children && image) {
    return (
      <header className="relative bg-cta overflow-hidden">
        <div className="max-w-screen-xl mx-auto">
          <div className="relative z-10 pb-8 bg-cta sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-cta transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <div className="-mr-2 flex items-center md:hidden">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                        id="main-menu"
                        aria-label="Main menu"
                        aria-haspopup="true"
                      ></button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block md:ml-10 md:pr-4"></div>
              </nav>
            </div>

            <main className="mt-1 mx-auto max-w-screen-xl px-4 sm:mt-4 sm:px-6 md:mt-8 lg:mt-10 lg:px-8 xl:mt-14">
              <div className="sm:text-center lg:text-left">{children}</div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={image}
            alt=""
          />
        </div>
      </header>
    );
  }

  if (children) {
    const headerStyle = { backgroundImage: `url('${image}')` };
    return (
      <header
        className="bg-cover bg-center min-h-1/4 sm:min-h-1/4 md:min-h-1/2"
        style={headerStyle}
      >
        {children}
      </header>
    );
  }

  return (
    <header className="flex align-middle bg-cta min-h-1/10 md:min-h-1/10 justify-center">
      <h2 className="mb-auto mt-auto text-5xl uppercase text-center text-secondary font-black">
        {title}
      </h2>
    </header>
  );
};

export default PageHeader;
