import React, { ReactNode } from "react";
import Head from "next/head";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

type Props = {
  children?: ReactNode;
  title?: string;
  fbTitle?: string;
  fbImage?: string;
  fbDescription?: string;
};

const baseTitle = "The BMX Devils";

const Layout = ({
  children,
  title = baseTitle,
  fbTitle = title,
  fbImage,
  fbDescription = baseTitle,
}: Props): JSX.Element => (
  <div className="min-h-screen bg-black max-h-full">
    <Head>
      <title>{title === baseTitle ? title : `${title} - ${baseTitle}`}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta
        property="og:title"
        content={fbTitle ? fbTitle : title ? title : baseTitle}
      />
      <meta
        property="og:image"
        content={fbImage ? fbImage : "/images/devil.jpg"}
      />
      <meta property="og:description" content={fbDescription} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff"></meta>
    </Head>
    <Header></Header>
    {children}
  </div>
);

export default Layout;
