import * as React from "react";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";

const MenuLink = ({
  href,
  children,
}: React.PropsWithChildren<LinkProps>): JSX.Element => {
  const router = useRouter();

  if (React.isValidElement(children)) {
    let className = children.props.className || "";
    if (router.pathname === href && children.props.className) {
      className = `${className} nav-item-active`;
    }

    return (
      <Link href={href}>{React.cloneElement(children, { className })}</Link>
    );
  }

  return <Link href={href}></Link>;
};

export default MenuLink;
