import React, { useState, useCallback } from "react";
import Link from "next/link";
import { MenuItem } from "../../interfaces";
import Menu from "./Menu/Menu";
import Hamburger from "./Menu/Hamburger";
import MobileMenu from "./Menu/MobileMenu";

const links: MenuItem[] = [
  {
    title: "Terug naar de website",
    link: "https://bmxdevils.be",
  },
  /*{
    title: "Lid worden",
    link: "/lid-worden",
  }
  {
    title: 'trainingen',
    link: '/',
  },
  {
    title: 'Wedstrijden',
    link: '/',
  },
  {
    title: "Verhuur",
    link: "/verhuur",
  },
  {
    title: "Contact",
    link: "/contact",
  },*/
];

const Header = (): JSX.Element => {
  const [showHBMenu, setShowHBMenu] = useState(false);

  const toggleHandler = useCallback(() => setShowHBMenu(!showHBMenu), [
    showHBMenu,
    setShowHBMenu,
  ]);

  return (
    <nav className="bg-primary">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex items-center">
            <Link href="/">
              <a className="flex items-center justify-start">
                <img
                  className="block h-16 w-auto"
                  src="/images/devil.svg"
                  alt="The BMX Devils"
                />

                <h1 className="block text-lg sm:text-2xl ml-6 text-cta">
                  The BMX Devils
                </h1>
              </a>
            </Link>
            <Menu links={links} />
          </div>
          <Hamburger showHBMenu={showHBMenu} toggleHandler={toggleHandler} />
        </div>
      </div>
      <MobileMenu
        links={links}
        showHBMenu={showHBMenu}
        toggleHandler={toggleHandler}
      />
    </nav>
  );
};

export default Header;
