import * as React from "react";
import { MenuItem } from "../../../interfaces";
import MenuLink from "./MenuLink";

type Props = {
  links: MenuItem[];
};

const Menu = ({ links }: Props): JSX.Element => (
  <div className="hidden lg:block lg:ml-6">
    <div className="flex">
      {links.map((link) => (
        <MenuLink key={link.link} href={link.link}>
          <a className="nav-item">{link.title}</a>
        </MenuLink>
      ))}
    </div>
  </div>
);

export default Menu;
