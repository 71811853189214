import * as React from "react";
import { MenuItem } from "../../../interfaces";
import MenuLink from "./MenuLink";

type Props = {
  showHBMenu: boolean;
  links: MenuItem[];
  toggleHandler: () => void;
};

const MobileMenu = ({
  showHBMenu,
  links,
  toggleHandler,
}: Props): JSX.Element => {

  return (
    <div className={`lg:hidden ${!showHBMenu && "hidden"}`}>
      <div className="px-2 pt-2 pb-3">
        {links.map((link) => (
          <MenuLink key={link.link} href={link.link}>
            <a className="nav-item nav-item-hb" onClick={toggleHandler}>
              {link.title}
            </a>
          </MenuLink>
        ))}
      </div>
    </div>
  );
};

export default MobileMenu;
