import * as React from "react";

type Props = {
  showHBMenu: boolean;
  toggleHandler: () => void;
};

const Hamburger = ({ showHBMenu, toggleHandler }: Props): JSX.Element => {
  return (
    <div className="-mr-2 flex lg:hidden">
      <button
        className="inline-flex items-center justify-center p-2 rounded-md text-secondary hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
        aria-label="Main menu"
        aria-expanded="false"
        onClick={toggleHandler}
      >
        <svg
          className={`block h-6 w-6 ${showHBMenu && "hidden"}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>

        <svg
          className={`block h-6 w-6 ${!showHBMenu && "hidden"}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
};

export default Hamburger;
